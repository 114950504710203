

.header {
    width: 100%;
    height: 44px;
    background-color: #1d1d1f;
    z-index: 9000;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0px;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
}

.nav__wordmark{
    z-index: 10001;
    position: absolute;
}

.nav__wordmark h1 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 700;
    margin-left: 15px;
}

.header a {
    text-decoration: none;
    font-size: 14px;
    transition: 0.1s;
    cursor: pointer;
}

.nav__toggle {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 25px;
    height: 25px;
    background-color: #1d1d1f;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
    border-radius: 50px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    outline: 0;
    border: 0;
    z-index: 10001;
    padding: 0;
    line-height: normal;
    text-align: inherit;
    overflow: visible;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.nav__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav__menu {
    text-align: center;
}

@media (max-width: 991px) {
    .nav__container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        z-index: 10000;
        background: hsla(0,0%,100%,.2);
        -webkit-backdrop-filter: blur(12px);
        backdrop-filter: blur(12px);
    }
    
    .header a {
        color: #1d1d1f !important;
        display: block;
    }

    .nav__wordmark h1 {
        color: #1d1d1f;
    }

    .nav__wrapper {
        height: 100%;
        width: 100%;
    }
    .nav__menu a {
        display: block;
        font-size: 2rem;
        margin: 30px 0;
    }
}

@media (min-width: 992px) { /* On large screens, show the grey menu */
    .nav__toggle {
        display: none;
    }
    .header {
        display: flex;
        align-items: center;
    }    
    .nav__menu {
        display: flex;
        align-items: center;
        column-gap: 2rem;
    }
}






.videoplayer_play_button:active,.videoplayer_play_button:hover {
    color: #F5F5F5;
    background-color: hsla(0,0%,69%,.6)
}