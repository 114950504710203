.footer {
    font-size: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;;
}

@media (min-width: 768px) {
    .footer {
        text-align: right;
    }
}
