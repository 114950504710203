html {
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100vw;
}

body {
  font-weight: 400;
  background-color: #1d1d1f;
  color: #F5F5F5;
}

body.disable_scroll {
  overflow: hidden;
}

main {
  font-family: var(--texgyre-font);
  font-size: .9rem;
}

section {
  min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--texgyre-font);
  font-weight: 400;
  color: #F5F5F5;
  margin-bottom: .7rem;
}

h4 {
  font-size: calc(.8rem + .8vw);
}

@media (min-width: 1200px) {
  h3 {
    font-size: 1.6rem;
  }
  h4 {
    font-size: 1.3rem;    
  }
}

a {
  color: #F5F5F5 !important;
}

.nav_mobile_hidden {
  display: block;
}

@media (max-width: 991px) {
  .nav_mobile_hidden .container{
      display: none;
  }
  .nav_mobile_hidden a h1 {
    color: #F5F5F5 !important;
  }
  .nav_mobile_hidden button {
    background-color: #F5F5F5;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
  }
}

.header.nav_mobile_hidden.hide {
  top: -44px;
}

.main-context {
  overflow-x: clip;
}

.section_divider {
  height: 60vh;
  display: block;
  padding-top: 20vh;
  -webkit-overflow-scrolling: auto;
  will-change: transform;
}